<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>高校</el-breadcrumb-item>
                <el-breadcrumb-item>新闻分类</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box">
            <div class="serarch  flex align-center justify-between">
                <div class="searchleft">
                    <el-button type="primary" icon="el-icon-circle-plus-outline" @click="openAdd">添加分类</el-button>
                </div>
                <div class="searchright">
                    <!-- <el-button type="primary" icon="el-icon-circle-plus-outline" @click="openAdd">添加高校</el-button> -->
                </div>
            </div>
            <div class="list" v-loading="loading" style="margin-top:15px;">
                <el-table
                :data="list"
                :header-cell-style="{
                    'font-weight': 'bold',
                    color: '#555555',
                    padding: '6px 0',
                }"
                stripe
                border
                style="width: 100%"
                >
                    <el-table-column prop="className" label="分类名称" >
                    </el-table-column>
                    <el-table-column prop="address" width="160" label="操作">
                        <template slot-scope="scope">
                        <el-button
                            type="primary"
                            @click="openEdit(scope.row)"
                            >编辑</el-button
                        >
                        <el-button
                            type="danger"
                            v-preventReClick
                            @click="deleteItem(scope.row)"
                            >删除</el-button
                        >
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <Addclass :show.sync="show" :selectobj="selectobj" @getList="getList"></Addclass>
    </div>
</template>
<script>
import Addclass from './addClass.vue'
import apiUrl from '@/api/public'
import _api from '@/api/index'
export default{
    components:{
        Addclass,
    },
    data(){
        return{
            schoolId:localStorage.getItem("schoolId"),
            show:false,
            selectobj:null,
            loading:false,
            list:[],
        }
    },
    created(){
        this.getList()
    },
    methods:{
        deleteItem(row){
            console.log(row)
            this.$confirm('确认要删除吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {

                _api.get(apiUrl.classificationdelete,{id:row.id}).then(res=>{
                    if(res.code==200){
                        this.getList()
                    }
                })
            
            }).catch(() => {
         
            });
        },
        openEdit(row){
            this.selectobj=row
            this.show=true
        },
        openAdd(){
            this.selectobj=null
            this.show=true
        },
        getList(){
            this.loading=true
            _api.get(apiUrl.classificationlist,{type:this.schoolId}).then(res=>{
                this.loading=false
                console.log(res)
                if(res.code==200){
                    this.list=res.data
                }
            })
        }
    }
}
</script>


