<template>
    <el-dialog
    :title="status==1?'添加分类':'编辑分类'"
    :visible.sync="show"
    width="30%"
    @open="open"
    :before-close="handleClose">
    <div>
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="分类名称">
                <el-input v-model="form.className" style="width:400px;"></el-input>
            </el-form-item>
           
        </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
    </el-dialog>
</template>
<script>
import apiUrl from '@/api/public'
import _api from '@/api/index'
export default{
    props:{
        show:{
            type:Boolean,
            default:false
        },
        selectobj:{
            type:Object,
            default:null
        }
    },
    data(){
        return{
            status:1,
            form:{
                id:null,
                className:null,
                type:null
            }
        }
    },
    methods:{
        handleClose(){
            this.form={
                id:null,
                className:null,
                type:null
            }
            this.$emit("update:show",false)
        },
        open(){
            this.form.type=localStorage.getItem("schoolId")
            if(this.selectobj){
                this.status=2
                for(let k in this.form){
                    this.form[k]=this.selectobj[k]
                }
            }else{
                this.status=1
            }
        },
        confirm(){
            let apiu=this.status==1?apiUrl.classification:apiUrl.classificationedit
            _api.get(apiu,this.form).then(res=>{
                console.log(res)
                if(res.code==200){
                    this.$message.success("操作成功！")
                    this.$emit("getList",null)
                    this.handleClose()
                }
            })
        }
    }
}
</script>


